import React, { Component } from "react";
import { connect } from "react-redux";
import {Tooltip, Popconfirm, Icon} from "antd";
import {
  changeApplicationStatus,
  resetUpdatedStatus,
  getApplications,
  setApplicationStoreParams,
  checkImportApplicationCancel,
  ImportApplicationCancel,
  ACTIONS
} from "../../../../../actions/adminActions";
import RegistrationForm from "../../../../RegistrationForm";
import {
  formatDateTimeFull,
  STATUS,
  formatAddress,
  formatInforUser,
  PERMISSION,
  STATUS_NUMBER
} from "../../../../../utils/commonUtils";
import { Translate } from "react-localize-redux";
import {
  PaginationBar,
  Table,
  Modal,
  Select, Checkbox, Button, Button as ButtonAntd
} from "../../../../../components/antd";
import {
  businessOptions,
  businessIds
} from "../../../../../constants/commonData";
import "./style.css";
import {STATUS as STATUSES} from "../../../../../reducers/adminReducer";
import Alert from "../../../../../components/Alert";

class CardCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      isShowModal: false,
      isOpenConfirm: false,
      importFile: false,
      file: null,
      fileName: 'No file choose',
      confirmImport: false,
      valueFile: ''
    };

    props.setTitle("キャンセル");
    props.setShowBox(false);

    this._columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            record.reAddCard ? <Checkbox
              checked={isCheckRow}
              onChange={e => this._onChangeCheckboxRow(e, record)}
            /> : ''
          );
        }
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <div className="style-icon">
            <Icon
              onClick={e => this.handleEditUser(e, record)}
              className="cell-edit-button"
              type="edit"
              theme="filled"
            />
          </div>
        )
      },
      {
        key: "businessMemberId",
        width: 150,
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId",
        render: (cell, record) => (
          <div>
            <div>
              {record.businessMemberId}
            </div>
            <div>
              {record.reAddCard ? '' : 'カード再発行'}
            </div>
          </div>
        )
      },
      {
        key: "lastname",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          return (
            <div>
              <div>{record.firstname}</div>
              <div>{record.lastname}</div>
            </div>
          );
        }
      },
      {
        key: "note",
        width: 300,
        title: "ノート",
        align: "center",
        dataIndex: "note"
      },
      {
        key: "reason",
        width: 200,
        title: "理由",
        align: "center",
        dataIndex: "reason"
      },
      {
        key: "lastNameChinese",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div/>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        title: "電話番号",
        align: "center",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        width: 400,
        title: "住所",
        align: "center",
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              <span className="address-two-line">{title}</span>
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        dataIndex: "postCode",
        title: "郵便番号"
      },
      {
        key: "email",
        align: "center",
        width: 300,
        dataIndex: "email",
        title: "メールアドレス"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        width: 140,
        align: "center",
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        width: 140,
        align: "center",
        dataIndex: "createdAt",
        title: "カード申請日"
      },
      {
        key: "sgpExchangeUserId",
        width: 250,
        align: "center",
        dataIndex: "sgpExchangeUserId",
        title: "SGPアカウント"
      },
      {
        align: "center",
        key: "_id",
        width: 300,
        dataIndex: "_id",
        title: "問い合わせ番号"
      },
      {
        key: "changeStatusTimestamp",
        align: "center",
        width: 300,
        title: "最終更新",
        render: (item, record) => (
          <div>{formatDateTimeFull(record.changeStatusTimestamp)}</div>
        )
      },
      {
        key: "cards",
        align: "center",
        width: 200,
        title: "古いカード番号",
        render: (item, record) => (
          record.cards && record.cards.map(e => <div>{e.cardNumber}</div>)
        )
      },
      {
        key: "cardNew",
        align: "center",
        width: 200,
        title: "新しいカード番号",
        render: (item, record) => (
          record.cardNew && record.cardNew.map(e => <div>{e.cardNumber}</div>)
        )
      },
      {
        key: "status",
        align: "center",
        width: 100,
        title: "古いステップ",
        render: (item, record) => {
          if (typeof record.status === 'object') {
            return record.status && record.status.map(e => <div>{STATUS_NUMBER[e]}</div>)
          } else {
            const statusItem = Object.values(STATUS).find(e => e === record.status)
            return <div>{STATUS_NUMBER[statusItem]}</div>
          }
        }
      },
      {
        key: "statusNew",
        align: "center",
        width: 100,
        dataIndex: "statusNew",
        title: "新しいステップ",
        render: (item, record) => {
          const statusItem = Object.values(STATUS).find(e => e === record.statusNew)
          return <div>{STATUS_NUMBER[statusItem]}</div>
        }
      },
    ];
    this.columnsTableImport = [
      {
        title: "No",
        align: "center",
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div>
            {index + 1}
          </div>
        )
      },
      {
        key: "businessMemberId",
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId"
      },
    ];
  }

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.CANCELED,
      type: type ? type : "status"
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.CANCELED,
      type: "params"
    });

    getApplications(payload);
  };

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.filter(e => e.reAddCard).length;
      checkAll = newCheckRows.length === users.filter(e => e.reAddCard).length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.filter(e => e.reAddCard).forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      businessId: null
    });
  }

  componentDidUpdate() {
    const {
      applicationStore: { status }
    } = this.props;
    const { confirmImport } = this.state;

    if (status === STATUSES.CHECK_IMPORT_APPLICATION_CANCEL_SUCCESS && !confirmImport) {
      this.setState({ confirmImport: true });
      this.props.resetUpdatedStatus();
    }

    if (status === STATUSES.IMPORT_APPLICATION_CANCEL_SUCCESS) {
      this.setState({
        confirmImport: false,
        importFile: false,
        isOpenConfirm: false,
        file: null,
        fileName: 'No file choose',
        valueFile: ''
      });
      this.props.resetUpdatedStatus();
    }

    if ([STATUSES.IMPORT_APPLICATION_CANCEL_SUCCESS, STATUSES.CHANGE_APPLICATION_STATUS_SUCCESS].includes(status)) {
      this._getApplications({
        page: 1,
        limit: 100,
        str: "",
        businessId: null
      });
      this.props.resetUpdatedStatus();
    }
  }

  handleEditUser(e, item) {
    e.preventDefault();
    this.setState({ selectedUser: item, isShowModal: true });
  }

  closeModal = () => {
    this.props.resetUpdatedStatus();
    this.setState({
      isShowModal: !this.state.isShowModal,
      selectedUser: null,
      tickUsers: []
    });
  };

  renderUserFormDialog() {
    const { selectedUser } = this.state;
    const {
      applicationStore: { listPermission }
    } = this.props;
    if (selectedUser) {
      let newSelectedUser = {
        ...selectedUser,
        ...formatInforUser(selectedUser),
        alertAck: true
      };
      return (
        <Modal
          size={"ls"}
          onCancel={this.closeModal}
          title="Detail User"
          visible={this.state.isShowModal}
          className="registration-form-modal"
        >
          <RegistrationForm
            status={selectedUser.status[selectedUser.status.length - 1]}
            data={newSelectedUser}
            update={listPermission.includes(PERMISSION.EDIT_CANCEL_LIST)}
            isUpdate={true}
            editable={true}
            onClose={() => this.closeModal()}
            editMail={true}
            showNote={true}
            step={STATUS.CANCELED}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  _renderPopoverConfirm = (onConfirm, content) => (
    <Popconfirm
      title={
        <>
          Are you sure?
          <br /> Please confirm again.
        </>
      }
      onConfirm={onConfirm}
      okText="Yes"
      icon={null}
      cancelText="Cancel"
    >
      {content}
    </Popconfirm>
  );

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  onCloseChooseFile = () => {

    this.setState({
      importFile: false,
      confirmImport: false,
      isOpenConfirm: false,
      file: null,
      fileName: 'No file choose',
      valueFile: ''
    })
    this.props.resetUpdatedStatus();
  }

  onCloseConfirmImport = () => {
    this.setState({confirmImport: false})
    this.props.resetUpdatedStatus();
  }

  handleChange(name) {
    return e => {
      if (e.target) {
        this.setState({[name]: e.target.value});
      }
      if (e.target.files) {
        this.setState({
          [name]:
            e.target.files && e.target.files.length ? e.target.files[0] : null,
          valueFile: e.target.value
        });
        this.setState({fileName: e.target.files[0] ? e.target.files[0].name: 'No file choose'})
      }
    };
  }

  renderImportDialog () {
    const {importFile, file, valueFile} = this.state;
      const {
          applicationStore: {
              status
          }
      } = this.props;

    return (
      <Modal
        size={"ls"}
        onCancel={this.onCloseChooseFile}
        title="Choose file"
        visible={importFile}
        className="import-csv"
      >
        <div className="form">
          <div className="field">
            <p className="label">Open file</p>
            <div>
              <label htmlFor="fileSelect" className="input-upload-card fa fa-upload">
                Upload
              </label>
              <input
                className='choose-file'
                type="file"
                id="fileSelect"
                accept=".csv"
                value={valueFile}
                onChange={this.handleChange("file")}
              />
              <div className='show-file-name'>
                {this.state.fileName}
              </div>
            </div>
          </div>
          <div className="text-center mt-4 action-box field">
            <p className="label" />
            <div>
              <ButtonAntd
                className='btn-import'
                color="secondary"
                variant="contained"
                disabled={status === STATUSES.CHECK_IMPORT_APPLICATION_CANCEL_PROGRESS || !file}
                onClick={() => {
                  this.props.checkImportApplicationCancel({file})
                }}
                loading={status === STATUSES.CHECK_IMPORT_APPLICATION_CANCEL_PROGRESS}
              >
                Import
              </ButtonAntd>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderAppCancel() {
    const { confirmImport, file } = this.state;
    const {
      applicationStore: {
        status,
        checkListAppCancel
      }
    } = this.props;
    return (
      <Modal
        size={"ls"}
        onCancel={this.onCloseConfirmImport}
        title="以下無効なユーザーリスト、ご確認してください"
        visible={confirmImport}
        className="import-csv"
      >
        <div className="form">
          <div>数量: {checkListAppCancel && checkListAppCancel.length}</div>
          <Table
            rowKey={(row, index) => index}
            columns={this.columnsTableImport}
            dataSource={checkListAppCancel}
          />
          <div className="text-center mt-4 action-box field">
            <div className='justify-content-center'>
              <ButtonAntd
                className='btn-import'
                color="secondary"
                variant="contained"
                disabled={status === STATUSES.IMPORT_APPLICATION_CANCEL_PROGRESS}
                onClick={() => {
                  this.props.ImportApplicationCancel({file})
                }}
                loading={status === STATUSES.IMPORT_APPLICATION_CANCEL_PROGRESS}
              >
                Ok
              </ButtonAntd>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    const {
      applicationStore: {
        users,
        params,
        submitting,
        check,
        listPermission,
        status,
      }
    } = this.props;
    const columns = this._columns;

    return (
      <Translate>
        {({ translate }) => (
          <React.Fragment>
            <div className="canceled-application">
              {this.renderUserFormDialog()}
              <div className="d-flex export-container">
                <Select
                  className="group-select"
                  options={businessOptions}
                  placeholder="ビジネスID"
                  modern
                  name="businessId"
                  size="small"
                  onChange={this._onBussinessType}
                  value={params.businessId}
                  allowClear
                />
                {listPermission.includes(
                  PERMISSION.UPDATE_STATUS_CANCELED_TO_CHECKED
                ) && (
                  <Button
                    size="small"
                    disabled={check.checkRows.length === 0}
                    loading={
                      status === STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS
                    }
                    onClick={() => {
                      this.setState({ isOpenConfirm: true });
                    }}
                  >
                    カード再発行 (Step 4)
                  </Button>
                )}
              </div>
              <div className="card-list">
                <div className="top-action-box">
                  <div className="button-group">
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                  <div className="button-group">
                    {listPermission.includes(
                      PERMISSION.UPDATE_STATUS_CANCELED_TO_CHECKED
                    ) && (
                      <Checkbox
                        onChange={this._onCheckAllChange}
                        checked={check.checkAll}
                        indeterminate={check.indeterminateCheckAll}
                        disabled={!users.find(e => e.reAddCard)}
                      >
                        全てをチェックする
                      </Checkbox>
                    )}
                    <ButtonAntd
                      color="secondary"
                      onClick={() => this.setState({importFile: true})}
                    >
                      Import CSV
                    </ButtonAntd>
                  </div>
                </div>
                <Table
                  loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                  rowKey={(row, index) => index}
                  columns={columns}
                  dataSource={users}
                  scroll={{ x: 4820, y: "calc(100vh - 328px)" }}
                />
              </div>
            </div>
            {this.renderImportDialog()}
            {this.renderAppCancel()}
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.changeStatus({
                    status: STATUS.CHECKED,
                    fromStatus: STATUS.CANCELED,
                    data: check.checkRows
                  });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
            {
              [
                STATUSES.CHECK_IMPORT_APPLICATION_CANCEL_FAIL,
                STATUSES.IMPORT_APPLICATION_CANCEL_FAIL,
              ].includes(status) && (
              <Alert
                title="Error"
                content={translate(this.props.adminError && ("errors." + this.props.adminError.code))}
                okTitle="OK"
                onOK={() => {
                  this.props.resetUpdatedStatus();
                }}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin,
    adminError: state.admin.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    changeStatus: data => {
      dispatch(changeApplicationStatus(data));
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    },
    checkImportApplicationCancel: (data) => {
      dispatch(checkImportApplicationCancel(data));
    },
    ImportApplicationCancel: (data) => {
      dispatch(ImportApplicationCancel(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCancel);
