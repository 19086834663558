import React, { Component } from "react";
import { connect } from "react-redux";
import { routes } from "../../../../routes";
import ezpLogo from "../../../../assets/images/logo.png";
import { withRouter } from "react-router-dom";
import "./style.css";

import styled from "styled-components";
import { Menu } from "antd";
import { ROLE_SUPPER_ADMIN } from "../../../../utils/commonUtils";

const Box = styled.div`
  width: 0;
  box-shadow: 4px 0 22px 0 rgba(0, 0, 0, 0.07);
  z-index: 1;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.open {
    width: 300px;
  }

  .surfing-box {
    width: 300px;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;

    .top {
      height: 83px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
      }
    }

    .ant-menu {
      border-right: none;

      .ant-menu-item {
        font-size: 15px;
        color: #717171;
        height: 60px;
        padding-top: 11px;
        margin: 0;
        user-select: none;

        &::after {
          left: 0;
          right: auto;
          border-right: 5px solid #f47629;
        }

        &:hover {
          color: #717171;
        }

        &.ant-menu-item-selected {
          background-color: #f7f7f7;
          font-weight: 600;
          color: #4b4b4b;
        }
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          margin: 0;
          font-size: 15px;
          color: #717171;
          height: 60px;
          padding-top: 11px;
          user-select: none;

          &:hover {
            color: #717171;
          }
        }

        .ant-menu {
          .ant-menu-item {
            border-bottom: 1px solid #e8e8e8;
            height: 70px;
            padding-top: 0;
            padding-left: 35px !important;
            display: flex;
            align-items: center;

            .custom {
              display: flex;
              align-items: center;

              span {
                width: 45px;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0.31px;
                color: #4b4b4b;
                display: block;
                margin-right: 10px;
              }

              p {
                flex: 1;
                word-wrap: break-word;
                white-space: pre-wrap;
                min-width: 0;
                font-size: 14px;
                line-height: 1.64;
                letter-spacing: 0.27px;
                color: #9d9d9d;
                margin-bottom: 0;
                font-weight: bold;
              }
            }
          }
        }

        &.ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            background-color: #f7f7f7;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

class MenuBar extends Component {
  _onMenuItemSelect = e => {
    const { history } = this.props;
    history.push(e.key);
  };

  _renderMenuItems = item => {
    const {
      adminStore: { currentUser }
    } = this.props;
    const { permission, name } = currentUser.roleId;

    if (item.children) {
      const children = item.children
        .filter(subItemFilter => {
          return (
            subItemFilter.permission &&
            permission.includes(subItemFilter.permission)
          );
        })
        .map(subItem => (
          <Menu.Item key={subItem.link}>
            <div className="custom">
              <span>{subItem.no}</span>
              <p>{`${subItem.name}${subItem.extend ? subItem.extend : ""}`}</p>
            </div>
          </Menu.Item>
        ));
      if (children.length)
        return (
          <Menu.SubMenu key={item.link} title={item.name}>
            {children}
          </Menu.SubMenu>
        );
    }
    if (
      typeof item.permission === "object" &&
      item.permission.filter(per => permission.includes(per)).length
    ) {
      return (
        <Menu.Item key={item.link}>
          <span>{item.name}</span>
        </Menu.Item>
      );
    } else {
      return name === ROLE_SUPPER_ADMIN ||
        (item.permission && permission.includes(item.permission)) ? (
        <Menu.Item key={item.link}>
          <span>{item.name}</span>
        </Menu.Item>
      ) : null;
    }
  };

  render() {
    const {
      adminStore: { currentUser }
    } = this.props;

    const { uiStore } = this.props;
    const fullPath = this.props.location.pathname.split("/");
    const subActive = `/${fullPath[1]}/${fullPath[2]}`;
    return (
      <Box className={uiStore.isSideBarOpen ? "open" : ""}>
        <div className="surfing-box">
          <div className="top">
            <img src={ezpLogo} width="200px" alt="Ezp logo" className="logo" />
          </div>
          <Menu
            className="main-menu"
            onClick={this._onMenuItemSelect}
            defaultSelectedKeys={[this.props.location.pathname]}
            selectedKeys={[this.props.location.pathname]}
            defaultOpenKeys={[subActive]}
            mode="inline"
          >
            {currentUser && routes.map(this._renderMenuItems)}
          </Menu>
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    uiStore: state.ui,
    adminStore: state.admin
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuBar)
);
