import "./style.css";
import cx from "classnames";
import { connect } from "react-redux";
import { Icon, Tooltip } from "antd";
import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import Alert from "../../../../../components/Alert";
import RegistrationForm from "../../../../RegistrationForm";
import { STATUS as STATUSES } from "../../../../../reducers/adminReducer";

import {
  businessIds,
  businessOptions,
} from "../../../../../constants/commonData";

import {
  Modal,
  Table,
  Button,
  Select,
  Checkbox,
  PaginationBar,
} from "../../../../../components/antd";

import {
  STATUS,
  PERMISSION,
  formatAddress,
  formatInforUser,
  formatDateTimeFull,
} from "../../../../../utils/commonUtils";

import {
  ACTIONS,
  getApplications,
  resetUpdatedStatus,
  exportPdfApplication,
  changeApplicationStatus,
  setApplicationStoreParams
} from "../../../../../actions/adminActions";

class ConfirmedReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      isShowModal: false,
      isOpenConfirm: false
    };
    props.setTitle("返還カード");
    props.setShowBox(false);
  }

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null,
      businessId: null,
      checkAddCard: false
    });
  }

  handleEditUser(item) {
    this.setState({ selectedUser: item, isShowModal: true });
  }

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.length;
      checkAll = newCheckRows.length === users.length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.CONFIRMED_RETURN,
      type: type ? type : "status",
      sort: true
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.CONFIRMED_RETURN,
      type: "params"
    });

    getApplications(payload);
  };

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  closeModal = () => {
    this.props.resetUpdatedStatus();
    this.setState({
      isShowModal: !this.state.isShowModal,
      selectedUser: null
    });
  };

  renderUserFormDialog() {
    const { selectedUser } = this.state;
    const {
      applicationStore: { listPermission }
    } = this.props;
    if (selectedUser) {
      let newSelectedUser = {
        ...selectedUser,
        ...formatInforUser(selectedUser),
        alertAck: true
      };
      return (
        <Modal
          size={"ls"}
          onCancel={this.closeModal}
          title="Detail User"
          visible={this.state.isShowModal}
          className="registration-form-modal"
        >
          <RegistrationForm
            status={selectedUser.status}
            data={newSelectedUser}
            isUpdate={true}
            update={listPermission.includes(PERMISSION.EDIT_CONFIRMED_RETURN_LIST)}
            onClose={() => this.closeModal()}
            editMail={true}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  _onCheckAddCard = () => {
    const {
      applicationStore: { params }
    } = this.props;

    this._getApplications(
      {
        checkAddCard: !params.checkAddCard,
        type: "params"
      },
      "search"
    );
  }

  render() {
    const {
      applicationStore: {
        users,
        params,
        submitting,
        check,
        status,
        linkExportPdf,
        listPermission
      }
    } = this.props;
    let columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            params.checkAddCard ? <Checkbox
                checked={params.checkAddCard}
              /> :
              <Checkbox
                checked={isCheckRow}
                onChange={e => this._onChangeCheckboxRow(e, record)}
              />
          );
        }
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <div className="style-icon">
            <Icon
              onClick={() => this.handleEditUser(record)}
              className="cell-edit-button"
              type="edit"
              theme="filled"
            />
          </div>
        )
      },
      {
        key: "note",
        width: 300,
        title: "理由",
        align: "center",
        dataIndex: "note"
      },
      {
        key: "businessMemberId",
        width: 130,
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId",
        render: (cell, record) => (
          <div>
            <div>
              {record.businessMemberId}
            </div>
            <div>
              {record.reAddCard ? 'カード再発行' : ''}
            </div>
          </div>
        )
      },
      {
        key: "letterTracking",
        width: 130,
        align: "center",
        title: "発送番号",
        dataIndex: "letterTracking"
      },
      {
        key: "lastname",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          return (
            <div>
              <div>{record.firstname}</div>
              <div>{record.lastname}</div>
            </div>
          );
        }
      },
      {
        key: "lastNameChinese",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        title: "電話番号",
        align: "center",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        width: 400,
        title: "住所",
        align: "center",
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              <span className="address-two-line">{title}</span>
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        align: "center",
        width: 300,
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        width: 140,
        align: "center",
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        width: 140,
        align: "center",
        title: "カード申請日",
        dataIndex: "createdAt"
      },
      {
        key: "sgpExchangeUserId",
        width: 250,
        align: "center",
        title: "SGPアカウント",
        dataIndex: "sgpExchangeUserId"
      },
      {
        align: "center",
        key: "_id",
        width: 300,
        title: "問い合わせ番号",
        render: (item, record) => <div>{record._id}</div>
      },
      {
        key: "changeStatusTimestamp",
        align: "center",
        width: 300,
        title: "最終更新",
        render: (item, record) => (
          <div>{formatDateTimeFull(record.changeStatusTimestamp)}</div>
        )
      }
    ];

    if (
      !listPermission.includes(
        PERMISSION.UPDATE_STATUS_CONFIRMED_RETURN_TO_CONFIRMED_PREPARING_RESHIPMENT
      ) &&
      !listPermission.includes(PERMISSION.EXPORT_PDF_CONFIRMED_RETURN_LIST)
    )
      columns = columns.filter(item => item.key !== "isChecked");

    return (
      <Translate>
        {({ translate }) => (
          <React.Fragment>
            <div className="before-sent-card">
              {this.renderUserFormDialog()}
              <div className="d-flex export-container">
                <Select
                  className="group-select"
                  options={businessOptions}
                  placeholder="ビジネスID"
                  modern
                  name="businessId"
                  size="small"
                  onChange={this._onBussinessType}
                  value={params.businessId}
                  allowClear
                />
                {listPermission.includes(
                  PERMISSION.UPDATE_STATUS_CONFIRMED_RETURN_TO_CONFIRMED_PREPARING_RESHIPMENT
                ) && (
                  <Button
                    size="small"
                    className="custom-btn"
                    disabled={check.checkRows.length === 0}
                    onClick={() => this.setState({ isOpenConfirm: true })}
                    loading={
                      status === STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS
                    }
                  >
                    ステータスアップ (8-3)
                  </Button>
                )}

                {listPermission.includes(
                  PERMISSION.EXPORT_PDF_CONFIRMED_RETURN_LIST
                ) && (
                  <Button
                    size="small"
                    className="custom-btn"
                    disabled={check.checkRows.length === 0}
                    loading={
                      status === STATUSES.EXPORT_PDF_APPLICATION_PROGRESS
                    }
                    onClick={() => {
                      this.props.handleExportPdf({
                        status: STATUS.CONFIRMED_RETURN,
                        data: check.checkRows
                      });
                    }}
                  >
                    PDF に
                  </Button>
                )}

                <div
                  className={cx(
                    !(
                      linkExportPdf &&
                      status === STATUSES.EXPORT_PDF_APPLICATION_SUCCESS
                    ) && "d-none"
                  )}
                >
                  <a href={linkExportPdf}>
                    こちらをクリックしてPDFを輸出します。
                  </a>
                </div>
              </div>
              <div className="card-list">
                <div className="top-action-box">
                  <div className="button-group">
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                  <div className="button-group">
                    {(listPermission.includes(
                      PERMISSION.UPDATE_STATUS_CONFIRMED_RETURN_TO_CONFIRMED_PREPARING_RESHIPMENT
                    ) ||
                      listPermission.includes(
                        PERMISSION.EXPORT_PDF_CONFIRMED_RETURN_LIST
                      )) && (
                      <Checkbox
                        onChange={this._onCheckAllChange}
                        checked={check.checkAll}
                        indeterminate={check.indeterminateCheckAll}
                      >
                        全てをチェックする
                      </Checkbox>
                    )}
                  </div>
                </div>
                <div className="top-action-box">
                  <div className="button-group">
                    <div className="action-box">
                      <Checkbox
                        className='check-re-add-card justify-content-end mb-3'
                        onChange={this._onCheckAddCard}
                        checked={params.checkAddCard}
                      >
                        全てカード再発行
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <Table
                  loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                  rowKey={(row, index) => index}
                  columns={columns}
                  dataSource={users}
                  scroll={{ x: 3950, y: "calc(100vh - 328px)" }}
                />
              </div>
            </div>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.changeStatus({
                    status: STATUS.CONFIRMED_PREPARING_RESHIPMENT,
                    fromStatus: STATUS.CONFIRMED_RETURN,
                    data: check.checkRows
                  });
                  this._onCheckAllChange({ target: { checked: false } });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStatus: data => {
      dispatch(changeApplicationStatus(data));
    },
    handleExportPdf: data => {
      dispatch(exportPdfApplication(data));
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedReturn);
