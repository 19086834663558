import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "antd";
import {
  changeApplicationStatus,
  resetUpdatedStatus,
  exportCSVApplication,
  exportExcelPrinted,
  getApplications,
  setApplicationStoreParams,
  ACTIONS
} from "../../../../../actions/adminActions";
import {
  Table,
  PaginationBar,
  Button,
  Select,
  Checkbox
} from "../../../../../components/antd";
import {
  formatCardNumber,
  formatDateTimeFull,
  STATUS,
  formatAddress,
  PERMISSION
} from "../../../../../utils/commonUtils";
import { STATUS as STATUSES } from "../../../../../reducers/adminReducer";
import Alert from "../../../../../components/Alert";
import { Translate } from "react-localize-redux";
import "./style.css";
import {
  businessOptions,
  businessIds
} from "../../../../../constants/commonData";

class ListPrintCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      isShowModal: false,
      exportAll: false,
      isOpenConfirm: false
    };
    props.setTitle("カード申し込み者リスト（決済済）");
    props.setShowBox(false);
  }

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null,
      businessId: null,
      checkAddCard: false
    });
  }

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.length;
      checkAll = newCheckRows.length === users.length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.PRINTED,
      type: type ? type : "status"
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.PRINTED,
      type: "params"
    });

    getApplications(payload);
  };

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  _onCheckAddCard = () => {
    const {
      applicationStore: { params }
    } = this.props;

    this._getApplications(
      {
        checkAddCard: !params.checkAddCard,
        type: "params"
      },
      "search"
    );
  }

  render() {
    const {
      applicationStore: {
        users,
        params,
        submitting,
        check,
        status,
        listPermission
      }
    } = this.props;
    const { exportAll } = this.state;

    let columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            params.checkAddCard ? <Checkbox
                checked={params.checkAddCard}
              /> :
              <Checkbox
                checked={isCheckRow}
                onChange={e => this._onChangeCheckboxRow(e, record)}
              />
          );
        }
      },
      {
        key: "businessMemberId",
        width: 150,
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId",
        render: (cell, record) => (
          <div>
            <div>
              {record.businessMemberId}
            </div>
            <div>
              {record.reAddCard ? 'カード再発行' : ''}
            </div>
          </div>
        )
      },
      {
        key: "cards",
        width: 160,
        align: "center",
        title: "申し込み枚数",
        render: (item, record) =>
          Array.isArray(record.cards) ? (
            <div>{record.cards.length}</div>
          ) : (
            <div></div>
          )
      },
      {
        align: "center",
        width: 300,
        key: "cardNumber1",
        title: (
          <>
            カード番号１
            <br />
            有効期限
          </>
        ),
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const cardNumber = card && card.cardNumber;
          const expiredDay = card && card.expiredDay;
          return (
            <>
              {formatCardNumber(cardNumber)}
              <br />
              {expiredDay && `(${expiredDay})`}
            </>
          );
        }
      },
      {
        align: "center",
        width: 130,
        key: "box",
        title: <>Box No.</>,
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const box = card && card.box;
          return <>{box ? `${box} (${record.cards[0].no})` : ""}</>;
        }
      },
      {
        align: "center",
        width: 130,
        key: "letterTracking",
        title: <>Delivery No.</>,
        render: (cell, record) => <>{record.letterTracking}</>
      },
      {
        key: "firstname",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.firstname}</div>
            <div>{record.lastname}</div>
          </>
        )
      },
      {
        key: "lastNameChinese",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        align: "center",
        title: "電話番号",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        align: "center",
        title: "住所",
        width: 400,
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              {title}
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        width: 300,
        align: "center",
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        align: "center",
        width: 120,
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        align: "center",
        width: 140,
        dataIndex: "createdAt",
        title: "カード申請日"
      },
      {
        key: "sgpExchangeUserId",
        align: "center",
        width: 250,
        title: "SGPアカウント",
        dataIndex: "sgpExchangeUserId"
      },
      {
        key: "_id",
        align: "center",
        width: 300,
        title: "問い合わせ番号",
        dataIndex: "_id"
      },
      {
        key: "changeStatusTimestamp",
        align: "center",
        width: 300,
        title: "最終更新",
        render: (item, record) => <div>{formatDateTimeFull(record.changeStatusTimestamp)}</div>
      }
    ];

    if (
      !listPermission.includes(PERMISSION.UPDATE_STATUS_PRINTED_TO_INPUT) &&
      !listPermission.includes(PERMISSION.EXPORT_EXCEL_PRINTED_LIST) &&
      !listPermission.includes(
        PERMISSION.UPDATE_STATUS_PRINTED_TO_CARD_UPDATE_LIST
      )
    )
      columns = columns.filter(item => item.key !== "isChecked");

    return (
      <Translate>
        {({ translate }) => (
          <>
            <div className="print-card-list card-list">
              <div className="top-action-box">
                <div>
                  <div className="card-infor">
                    <table>
                      <tbody>
                        <tr>
                          <td>Number of card need print in this list</td>
                          <td>
                            <b>{users.length}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Remaining card in Stock</td>
                          <td>
                            <b>{params.unusedCard}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Current waiting card quantity in checked list</td>
                          <td>
                            <b>{params.checkedApplication}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="button-group">
                    <Select
                      className="group-select"
                      options={businessOptions}
                      placeholder="ビジネスID"
                      modern
                      name="businessId"
                      size="small"
                      onChange={this._onBussinessType}
                      value={params.businessId}
                      allowClear
                    />
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                </div>
                <div className="button-group">
                  <div className="action-box">
                    {listPermission.includes(
                      PERMISSION.EXPORT_EXCEL_PRINTED_LIST
                    ) && (
                      <React.Fragment>
                        <div className="checkbox-export">
                          <Checkbox
                            onChange={() => {
                              this.setState({
                                exportAll: !exportAll
                              });
                            }}
                            checked={exportAll}
                            color="primary"
                          >
                            Select All page To Export
                          </Checkbox>
                        </div>
                        <div className="btn-group-export">
                          <Button
                            loading={
                              status === STATUSES.CSV_APPLICATION_EXPORTING
                            }
                            onClick={() =>
                              this.props.handleExportCSV({
                                status: STATUS.PRINTED,
                                data: check.checkRows,
                                exportAll: exportAll
                              })
                            }
                          >
                            Export Application
                          </Button>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="btn-group-check-all">
                      {(listPermission.includes(
                        PERMISSION.UPDATE_STATUS_PRINTED_TO_INPUT
                      ) ||
                        listPermission.includes(
                          PERMISSION.EXPORT_EXCEL_PRINTED_LIST
                        ) ||
                        listPermission.includes(
                          PERMISSION.UPDATE_STATUS_PRINTED_TO_CARD_UPDATE_LIST
                        )) && (
                        <React.Fragment>
                          <Checkbox
                            onChange={this._onCheckAllChange}
                            checked={check.checkAll}
                            indeterminate={check.indeterminateCheckAll}
                          >
                            全てをチェックする
                          </Checkbox>
                        </React.Fragment>
                      )}
                      {listPermission.includes(
                        PERMISSION.UPDATE_STATUS_PRINTED_TO_CARD_UPDATE_LIST
                      ) && (
                        <Button
                          className="update-status"
                          type="primary"
                          size="small"
                          disabled={check.checkRows.length === 0}
                          onClick={() =>
                            this.setState({
                              isOpenConfirm: true,
                              statusUpdate: STATUS.CARD_UPDATE
                            })
                          }
                        >
                          TO CARD UPDATE LIST (5-2)
                        </Button>
                      )}
                      {listPermission.includes(
                        PERMISSION.UPDATE_STATUS_PRINTED_TO_INPUT
                      ) && (
                        <Button
                          type="primary"
                          size="small"
                          disabled={check.checkRows.length === 0}
                          onClick={() =>
                            this.setState({
                              isOpenConfirm: true,
                              statusUpdate: STATUS.INPUT
                            })
                          }
                        >
                          STATUS UPDATE (6)
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="top-action-box">
                <div className="button-group">
                  <div className="action-box">
                    <Checkbox
                      className='check-re-add-card justify-content-end mb-3'
                      onChange={this._onCheckAddCard}
                      checked={params.checkAddCard}
                    >
                      全てカード再発行
                    </Checkbox>
                  </div>
                </div>
              </div>
              <Table
                loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={users}
                scroll={{ x: 4200, y: "calc(100vh - 426px)" }}
              />
            </div>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.toInputCards({
                    status: this.state.statusUpdate,
                    fromStatus: STATUS.PRINTED,
                    data: check.checkRows
                  });
                  this._onCheckAllChange({ target: { checked: false } });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
          </>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    toInputCards: data => {
      dispatch(changeApplicationStatus(data));
    },
    handleExportCSV: data => {
      dispatch(exportCSVApplication(data));
    },
    handleExportExcel: data => {
      dispatch(exportExcelPrinted(data));
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPrintCard);
