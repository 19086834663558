import queryString from "querystring";
import moment from "moment";
import { businessIds } from "../constants/commonData";

const STATUS = {
  INIT: "init",
  PENDING: "pending",
  DONE: "done",
  FAILED: "failed",
  REFUNDING: "refunding",
  REFUNDED: "refunded",
  CHECKED: "checked",
  PRINTED: "printed",
  CARD_UPDATE: "card_update",
  RECONFIRM: "reconfirm",
  RECONFIRMED: "reconfirmed",
  INPUT: "input",
  RESERVE: "reserve",
  SENT: "sent",
  CONFIRMING: "confirming",
  CONFIRMED: "confirmed",
  CONFIRMED_PREPARING_SHIPMENT: "confirmed_preparing_shipment",
  CONFIRMED_RETURN: "confirmed_return",
  CONFIRMED_PREPARING_RESHIPMENT: "confirmed_preparing_reshipment",
  COMPLETED: "completed",
  CARD_VALID: "card_valid",
  CANCELED: "canceled"
};

const STATUS_NUMBER = {
  pending: "1",
  done: "2",
  reconfirm: "3-0",
  reconfirmed: "3-1",
  checked: "4",
  printed: "5-0",
  reserve: "5-1",
  card_update: "5-2",
  input: "6",
  sent: "7",
  confirming: "7-1",
  confirmed: "8",
  confirmed_preparing_shipment: "8-1",
  confirmed_return: "8-2",
  confirmed_preparing_reshipment: "8-3",
  completed: "9",
  card_valid: "10",
  refunding: "0-0",
  refunded: "0-1",
  canceled: "20"
};

const STATUS_CARD_NUMBER = {
  UNUSED: "unused",
  USED: "used",
  REJECTED: "rejected"
};

const TYPE_SEND_MAIL = {
  FUZZY_PASSPORT: "fuzzy passport",
  WRONG_FULL_NAME: "wrong full name",
  WRONG_PHONE_NUMBER: "wrong phone number",
  WRONG_PASSPORT_EXPIRED: "wrong passport expired",
  FUZZY_SELFIE: "fuzzy selfie",
  WRONG_ADDRESS_DOC: "wrong address doc",
};

const STATUS_USER = {
  ACTIVE: "Active",
  DISABLED: "Disabled"
};

const ROLE_SUPPER_ADMIN = "super-admin";

const Validation = {
  validateEmail(value) {
    if (Array.isArray(value)) return true;
    let re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    let result = value.match(re);
    return result && result[0] === value;
  },

  checkDate(year, month, date) {
    return moment(`${year}-${month}-${date}`, "YYYY-M-D").isValid();
  },

  dateLessThanNow(year, month, date) {
    const momentDate = moment(`${year}-${month}-${date}`, "YYYY-M-D").valueOf();
    const now = moment().startOf('day').valueOf();
    return momentDate < now;
  },

  passportExpiry(year, month, date) {
    const ALLOW_MONTH_PASSPORT_EXPIRE_FROM_NOW = 7;
    const momentDate = moment(`${year}-${month}-${date}`, "YYYY-M-D").valueOf();
    const expiryTime = moment().startOf('day').add(ALLOW_MONTH_PASSPORT_EXPIRE_FROM_NOW, 'months').valueOf();
    return momentDate > expiryTime;
  },

  validatePostCode(value, isTriple) {
    const re = isTriple ? /^[0-9-]{1,10}$/ : /^[0-9]{1,7}$/;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validatePhoneNumber(value) {
    if (value && value.substr(0, 3) !== "+81") return true;
    const checkData = [
      "+81060",
      "+81070",
      "+81080",
      "+81090",
      "+8160",
      "+8170",
      "+8180",
      "+8190"
    ];
    for (let i = 0; i < checkData.length; i++) {
      if (value && value.includes(checkData[i])) return true;
    }
    return false;
  },

  validateName(value) {
    const re = /^[A-Z ]+$/;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validateChineseName (value) {
    const re = /^(?:[\u2E80-\u2FD5\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u2E80-\u2FD5\uFF5F-\uFF9F\u31F0-\u31FF\u3220-\u3243\u3280-\u337F\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf]{0,100}|)$/;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validateAfterAddress(value) {
    const re = /(([0-9]+(-[0-9]*)*)|([a-zA-Z-]*))(,?\s(([0-9]+(-[0-9]*)*)|([a-zA-Z-]*)))*/g;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validateAddress(value) {
    if (!value) return true;
    const re = /[a-zA-Z\s-()._<>0-9\\/]+(,?\s?[a-zA-Z-()._<>0-9\\/])*/g;
    let result = value.match(re);
    return result && result[0] === value;
  },
  validateNumber(value) {
    const re = /^[0-9]*$/;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validatePasswordCard(value) {
    const re = /^[A-Za-z0-9]+/g;
    let result = value.match(re);
    return result && result[0] === value;
  },

  validateGreenBox(value) {
    return value.charAt(0) === "A"||value.charAt(0) === "G";
  },

  checkSamePassword(password, confirmPasssword) {
    return password === confirmPasssword;
  },

  validateUploadFileSize(passportImage) {
    let checkPassport = null;
    if (passportImage.size > 4 * 1024 * 1024) {
      checkPassport = 1;
    } else {
      checkPassport = passportImage.size < 150 * 1024 ? -1 : 0;
    }
    return checkPassport;
  },

  validateUploadFileTripleSize (passportImage) {
    if(!passportImage) return;

    let checkPassport = null;
    if (passportImage.size > 4 * 1024 * 1024) {
      checkPassport = 1;
    } else {
      checkPassport = passportImage.size < 300 * 1024 ? -1 : 0;
    }
    return checkPassport;
  },
  passportNumber(value) {
    const valid = /^([0-9A-Z]*)$/.test(value)
    return !valid;
  },

  validateUploadFileType (image) {
    if(!image) return;

    const imageTail = ['jpg', 'JPG', 'jpeg', 'JPEG'];
    return image.type && !imageTail.includes(image.type.substr(6)) ? 1 : 0;
  }
};

const formatDateTimeFull = dateTime =>
  dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss Z") : null;

const formatCardNumber = cardNumber => {
  if (!cardNumber) return "";
  return `${cardNumber.substr(0, 4)} ${cardNumber.substr(4, 4)} ${cardNumber.substr(8, 4)} ${cardNumber.substr(12, 4)}`;
};

const getLatestSuccessStep = stepSuccess => {
  let step = 1;
  while (stepSuccess[step - 1]) {
    step++;
  }
  return step;
};

const parseUrlQuery = query => {
  if (query.indexOf("?") >= 0) query = query.substr(query.indexOf("?") + 1);
  return queryString.parse(query);
};

const getCurrentStepFromUrl = query => {
  let params = parseUrlQuery(query);
  return Number(params.step || "1") || 1;
};

const getTypeMai = value => {
  switch (value) {
    case TYPE_SEND_MAIL.FUZZY_PASSPORT:
      return "パスポートユーザー更新済み";
    case TYPE_SEND_MAIL.WRONG_FULL_NAME:
      return "名前修正済み";
    case TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED:
      return "パスポートの期限更新済み";
    case TYPE_SEND_MAIL.WRONG_PHONE_NUMBER:
      return "電話番号更新済";
    case TYPE_SEND_MAIL.FUZZY_SELFIE:
      return "Blue selfie image";
    case TYPE_SEND_MAIL.WRONG_ADDRESS_DOC:
      return "Wrong address doc"
    default:
      return "unknown type mail";
  }
};

const getFromUpdatedInfo = data => {
  let result = [];
  if (!data || data.length === 0) return result;
  data.map(val => result.push(getTypeMai(val)));
  return result.toString();
};

const getInfoHistoryUpdated = data => {
  let result = [];
  if (!data || data.length === 0) return result;
  if (Array.isArray(data)) {
    data.forEach(val => {
      result.push(getTypeMai(val.type));
      result.push(formatDateTimeFull(val.timestamp));
    });
  }
  return result;
};

const allCountries = [
  {
    name: "Afghanistan",
    code: "AFG"
  },
  {
    name: "Åland Islands",
    code: "ALA"
  },
  {
    name: "Albania",
    code: "ALB"
  },
  {
    name: "Algeria",
    code: "DZA"
  },
  {
    name: "American Samoa",
    code: "ASM"
  },
  {
    name: "Andorra",
    code: "AND"
  },
  {
    name: "Angola",
    code: "AGO"
  },
  {
    name: "Anguilla",
    code: "AIA"
  },
  {
    name: "Antarctica",
    code: "ATA"
  },
  {
    name: "Antigua and Barbuda",
    code: "ATG"
  },
  {
    name: "Argentina",
    code: "ARG"
  },
  {
    name: "Armenia",
    code: "ARM"
  },
  {
    name: "Aruba",
    code: "ABW"
  },
  {
    name: "Australia",
    code: "AUS"
  },
  {
    name: "Austria",
    code: "AUT"
  },
  {
    name: "Azerbaijan",
    code: "AZE"
  },
  {
    name: "Bahamas",
    code: "BHS"
  },
  {
    name: "Bahrain",
    code: "BHR"
  },
  {
    name: "Bangladesh",
    code: "BGD"
  },
  {
    name: "Barbados",
    code: "BRB"
  },
  {
    name: "Belarus",
    code: "BLR"
  },
  {
    name: "Belgium",
    code: "BEL"
  },
  {
    name: "Belize",
    code: "BLZ"
  },
  {
    name: "Benin",
    code: "BEN"
  },
  {
    name: "Bermuda",
    code: "BMU"
  },
  {
    name: "Bhutan",
    code: "BTN"
  },
  {
    name: "Bolivia (Plurinational State of)",
    code: "BOL"
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BES"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BIH"
  },
  {
    name: "Botswana",
    code: "BWA"
  },
  {
    name: "Bouvet Island",
    code: "BVT"
  },
  {
    name: "Brazil",
    code: "BRA"
  },
  {
    name: "British Indian Ocean Territory",
    code: "IOT"
  },
  {
    name: "Brunei Darussalam",
    code: "BRN"
  },
  {
    name: "Bulgaria",
    code: "BGR"
  },
  {
    name: "Burkina Faso",
    code: "BFA"
  },
  {
    name: "Burundi",
    code: "BDI"
  },
  {
    name: "Cabo Verde",
    code: "CPV"
  },
  {
    name: "Cambodia",
    code: "KHM"
  },
  {
    name: "Cameroon",
    code: "CMR"
  },
  {
    name: "Canada",
    code: "CAN"
  },
  {
    name: "Cayman Islands",
    code: "CYM"
  },
  {
    name: "Central African Republic",
    code: "CAF"
  },
  {
    name: "Chad",
    code: "TCD"
  },
  {
    name: "Chile",
    code: "CHL"
  },
  {
    name: "China",
    code: "CHN"
  },
  {
    name: "Christmas Island",
    code: "CXR"
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CCK"
  },
  {
    name: "Colombia",
    code: "COL"
  },
  {
    name: "Comoros",
    code: "COM"
  },
  {
    name: "Congo",
    code: "COG"
  },
  {
    name: "Congo (Democratic Republic of the)",
    code: "COD"
  },
  {
    name: "Cook Islands",
    code: "COK"
  },
  {
    name: "Costa Rica",
    code: "CRI"
  },
  {
    name: "Côte d'Ivoire",
    code: "CIV"
  },
  {
    name: "Croatia",
    code: "HRV"
  },
  {
    name: "Cuba",
    code: "CUB"
  },
  {
    name: "Curaçao",
    code: "CUW"
  },
  {
    name: "Cyprus",
    code: "CYP"
  },
  {
    name: "Czechia",
    code: "CZE"
  },
  {
    name: "Denmark",
    code: "DNK"
  },
  {
    name: "Djibouti",
    code: "DJI"
  },
  {
    name: "Dominica",
    code: "DMA"
  },
  {
    name: "Dominican Republic",
    code: "DOM"
  },
  {
    name: "Ecuador",
    code: "ECU"
  },
  {
    name: "Egypt",
    code: "EGY"
  },
  {
    name: "El Salvador",
    code: "SLV"
  },
  {
    name: "Equatorial Guinea",
    code: "GNQ"
  },
  {
    name: "Eritrea",
    code: "ERI"
  },
  {
    name: "Estonia",
    code: "EST"
  },
  {
    name: "Eswatini",
    code: "SWZ"
  },
  {
    name: "Ethiopia",
    code: "ETH"
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FLK"
  },
  {
    name: "Faroe Islands",
    code: "FRO"
  },
  {
    name: "Fiji",
    code: "FJI"
  },
  {
    name: "Finland",
    code: "FIN"
  },
  {
    name: "France",
    code: "FRA"
  },
  {
    name: "French Guiana",
    code: "GUF"
  },
  {
    name: "French Polynesia",
    code: "PYF"
  },
  {
    name: "French Southern Territories",
    code: "ATF"
  },
  {
    name: "Gabon",
    code: "GAB"
  },
  {
    name: "Gambia",
    code: "GMB"
  },
  {
    name: "Georgia",
    code: "GEO"
  },
  {
    name: "Germany",
    code: "DEU"
  },
  {
    name: "Ghana",
    code: "GHA"
  },
  {
    name: "Gibraltar",
    code: "GIB"
  },
  {
    name: "Greece",
    code: "GRC"
  },
  {
    name: "Greenland",
    code: "GRL"
  },
  {
    name: "Grenada",
    code: "GRD"
  },
  {
    name: "Guadeloupe",
    code: "GLP"
  },
  {
    name: "Guam",
    code: "GUM"
  },
  {
    name: "Guatemala",
    code: "GTM"
  },
  {
    name: "Guernsey",
    code: "GGY"
  },
  {
    name: "Guinea",
    code: "GIN"
  },
  {
    name: "Guinea-Bissau",
    code: "GNB"
  },
  {
    name: "Guyana",
    code: "GUY"
  },
  {
    name: "Haiti",
    code: "HTI"
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HMD"
  },
  {
    name: "Holy See",
    code: "VAT"
  },
  {
    name: "Honduras",
    code: "HND"
  },
  {
    name: "Hong Kong",
    code: "HKG"
  },
  {
    name: "Hungary",
    code: "HUN"
  },
  {
    name: "Iceland",
    code: "ISL"
  },
  {
    name: "India",
    code: "IND"
  },
  {
    name: "Indonesia",
    code: "IDN"
  },
  {
    name: "Iran (Islamic Republic of)",
    code: "IRN"
  },
  {
    name: "Iraq",
    code: "IRQ"
  },
  {
    name: "Ireland",
    code: "IRL"
  },
  {
    name: "Isle of Man",
    code: "IMN"
  },
  {
    name: "Israel",
    code: "ISR"
  },
  {
    name: "Italy",
    code: "ITA"
  },
  {
    name: "Jamaica",
    code: "JAM"
  },
  {
    name: "Japan",
    code: "JPN"
  },
  {
    name: "Jersey",
    code: "JEY"
  },
  {
    name: "Jordan",
    code: "JOR"
  },
  {
    name: "Kazakhstan",
    code: "KAZ"
  },
  {
    name: "Kenya",
    code: "KEN"
  },
  {
    name: "Kiribati",
    code: "KIR"
  },
  {
    name: "Korea (Democratic People's Republic of)",
    code: "PRK"
  },
  {
    name: "Korea (Republic of)",
    code: "KOR"
  },
  {
    name: "Kuwait",
    code: "KWT"
  },
  {
    name: "Kyrgyzstan",
    code: "KGZ"
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LAO"
  },
  {
    name: "Latvia",
    code: "LVA"
  },
  {
    name: "Lebanon",
    code: "LBN"
  },
  {
    name: "Lesotho",
    code: "LSO"
  },
  {
    name: "Liberia",
    code: "LBR"
  },
  {
    name: "Libya",
    code: "LBY"
  },
  {
    name: "Liechtenstein",
    code: "LIE"
  },
  {
    name: "Lithuania",
    code: "LTU"
  },
  {
    name: "Luxembourg",
    code: "LUX"
  },
  {
    name: "Macao",
    code: "MAC"
  },
  {
    name: "Macedonia (the former Yugoslav Republic of)",
    code: "MKD"
  },
  {
    name: "Madagascar",
    code: "MDG"
  },
  {
    name: "Malawi",
    code: "MWI"
  },
  {
    name: "Malaysia",
    code: "MYS"
  },
  {
    name: "Maldives",
    code: "MDV"
  },
  {
    name: "Mali",
    code: "MLI"
  },
  {
    name: "Malta",
    code: "MLT"
  },
  {
    name: "Marshall Islands",
    code: "MHL"
  },
  {
    name: "Martinique",
    code: "MTQ"
  },
  {
    name: "Mauritania",
    code: "MRT"
  },
  {
    name: "Mauritius",
    code: "MUS"
  },
  {
    name: "Mayotte",
    code: "MYT"
  },
  {
    name: "Mexico",
    code: "MEX"
  },
  {
    name: "Micronesia (Federated States of)",
    code: "FSM"
  },
  {
    name: "Moldova (Republic of)",
    code: "MDA"
  },
  {
    name: "Monaco",
    code: "MCO"
  },
  {
    name: "Mongolia",
    code: "MNG"
  },
  {
    name: "Montenegro",
    code: "MNE"
  },
  {
    name: "Montserrat",
    code: "MSR"
  },
  {
    name: "Morocco",
    code: "MAR"
  },
  {
    name: "Mozambique",
    code: "MOZ"
  },
  {
    name: "Myanmar",
    code: "MMR"
  },
  {
    name: "Namibia",
    code: "NAM"
  },
  {
    name: "Nauru",
    code: "NRU"
  },
  {
    name: "Nepal",
    code: "NPL"
  },
  {
    name: "Netherlands",
    code: "NLD"
  },
  {
    name: "New Caledonia",
    code: "NCL"
  },
  {
    name: "New Zealand",
    code: "NZL"
  },
  {
    name: "Nicaragua",
    code: "NIC"
  },
  {
    name: "Niger",
    code: "NER"
  },
  {
    name: "Nigeria",
    code: "NGA"
  },
  {
    name: "Niue",
    code: "NIU"
  },
  {
    name: "Norfolk Island",
    code: "NFK"
  },
  {
    name: "Northern Mariana Islands",
    code: "MNP"
  },
  {
    name: "Norway",
    code: "NOR"
  },
  {
    name: "Oman",
    code: "OMN"
  },
  {
    name: "Pakistan",
    code: "PAK"
  },
  {
    name: "Palau",
    code: "PLW"
  },
  {
    name: "Palestine, State of",
    code: "PSE"
  },
  {
    name: "Panama",
    code: "PAN"
  },
  {
    name: "Papua New Guinea",
    code: "PNG"
  },
  {
    name: "Paraguay",
    code: "PRY"
  },
  {
    name: "Peru",
    code: "PER"
  },
  {
    name: "Philippines",
    code: "PHL"
  },
  {
    name: "Pitcairn",
    code: "PCN"
  },
  {
    name: "Poland",
    code: "POL"
  },
  {
    name: "Portugal",
    code: "PRT"
  },
  {
    name: "Puerto Rico",
    code: "PRI"
  },
  {
    name: "Qatar",
    code: "QAT"
  },
  {
    name: "Réunion",
    code: "REU"
  },
  {
    name: "Romania",
    code: "ROU"
  },
  {
    name: "Russian Federation",
    code: "RUS"
  },
  {
    name: "Rwanda",
    code: "RWA"
  },
  {
    name: "Saint Barthélemy",
    code: "BLM"
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SHN"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KNA"
  },
  {
    name: "Saint Lucia",
    code: "LCA"
  },
  {
    name: "Saint Martin (French part)",
    code: "MAF"
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "SPM"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VCT"
  },
  {
    name: "Samoa",
    code: "WSM"
  },
  {
    name: "San Marino",
    code: "SMR"
  },
  {
    name: "Sao Tome and Principe",
    code: "STP"
  },
  {
    name: "Saudi Arabia",
    code: "SAU"
  },
  {
    name: "Senegal",
    code: "SEN"
  },
  {
    name: "Serbia",
    code: "SRB"
  },
  {
    name: "Seychelles",
    code: "SYC"
  },
  {
    name: "Sierra Leone",
    code: "SLE"
  },
  {
    name: "Singapore",
    code: "SGP"
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SXM"
  },
  {
    name: "Slovakia",
    code: "SVK"
  },
  {
    name: "Slovenia",
    code: "SVN"
  },
  {
    name: "Solomon Islands",
    code: "SLB"
  },
  {
    name: "Somalia",
    code: "SOM"
  },
  {
    name: "South Africa",
    code: "ZAF"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "SGS"
  },
  {
    name: "South Sudan",
    code: "SSD"
  },
  {
    name: "Spain",
    code: "ESP"
  },
  {
    name: "Sri Lanka",
    code: "LKA"
  },
  {
    name: "Sudan",
    code: "SDN"
  },
  {
    name: "Suriname",
    code: "SUR"
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJM"
  },
  {
    name: "Sweden",
    code: "SWE"
  },
  {
    name: "Switzerland",
    code: "CHE"
  },
  {
    name: "Syrian Arab Republic",
    code: "SYR"
  },
  {
    name: "Taiwan, Province of China",
    code: "TWN"
  },
  {
    name: "Tajikistan",
    code: "TJK"
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZA"
  },
  {
    name: "Thailand",
    code: "THA"
  },
  {
    name: "Timor-Leste",
    code: "TLS"
  },
  {
    name: "Togo",
    code: "TGO"
  },
  {
    name: "Tokelau",
    code: "TKL"
  },
  {
    name: "Tonga",
    code: "TON"
  },
  {
    name: "Trinidad and Tobago",
    code: "TTO"
  },
  {
    name: "Tunisia",
    code: "TUN"
  },
  {
    name: "Turkey",
    code: "TUR"
  },
  {
    name: "Turkmenistan",
    code: "TKM"
  },
  {
    name: "Turks and Caicos Islands",
    code: "TCA"
  },
  {
    name: "Tuvalu",
    code: "TUV"
  },
  {
    name: "Uganda",
    code: "UGA"
  },
  {
    name: "Ukraine",
    code: "UKR"
  },
  {
    name: "United Arab Emirates",
    code: "ARE"
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    code: "GBR"
  },
  {
    name: "United States of America",
    code: "USA"
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UMI"
  },
  {
    name: "Uruguay",
    code: "URY"
  },
  {
    name: "Uzbekistan",
    code: "UZB"
  },
  {
    name: "Vanuatu",
    code: "VUT"
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    code: "VEN"
  },
  {
    name: "Viet Nam",
    code: "VNM"
  },
  {
    name: "Virgin Islands (British)",
    code: "VGB"
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "VIR"
  },
  {
    name: "Wallis and Futuna",
    code: "WLF"
  },
  {
    name: "Western Sahara",
    code: "ESH"
  },
  {
    name: "Yemen",
    code: "YEM"
  },
  {
    name: "Zambia",
    code: "ZMB"
  },
  {
    name: "Zimbabwe",
    code: "ZWE"
  }
];

const allProfession = [
  "Agriculture and Forestry",
  "Forestry",
  "Fisheries",
  "Mining",
  "Construction Industry",
  "Manufacturing Industry",
  "Electricity and Gas",
  "Transportation and Communication Industry",
  "Wholesale · Retail · Food Service",
  "Finance / Insurance Industry",
  "Real Estate Business",
  "Service Industry",
  "Unclassifiable Industry",
  "Unemployed"
];

const professionOptions = allProfession.map(item => ({ value: item, name: item }));

const PERMISSION = {
  PERMISSION: "permission",
  MEMBER: "member",

  VIEW_UNPAY_LIST: `get_user_by_status_${STATUS.PENDING}`,
  EDIT_UNPAY_LIST: `update_application_info_${STATUS.PENDING}`,

  VIEW_DONE_LIST: `get_user_by_status_${STATUS.DONE}`,
  EDIT_DONE_LIST: `update_application_info_${STATUS.DONE}`,
  UPDATE_STATUS_DONE_TO_RECONFIRM: `change_application_status_from_${STATUS.DONE}_to_${STATUS.RECONFIRM}`,
  UPDATE_STATUS_DONE_TO_REFUNDING: `change_application_status_from_${STATUS.DONE}_to_${STATUS.REFUNDING}`,
  UPDATE_STATUS_DONE_TO_CHECKED: `change_application_status_from_${STATUS.DONE}_to_${STATUS.CHECKED}`,

  VIEW_RECONFIRM_LIST: `get_user_by_status_${STATUS.RECONFIRM}`,
  EDIT_RECONFIRM_LIST: `update_application_info_${STATUS.RECONFIRM}`,
  UPDATE_STATUS_REFUNDING_RECONFIRM_TO_REFUNDING: `change_application_status_from_${STATUS.RECONFIRM}_to_${STATUS.REFUNDING}`,
  UPDATE_STATUS_RECONFIRM_LIST_FROM_RECONFIRM_TO_CHECKED: `change_application_status_from_${STATUS.RECONFIRM}_to_${STATUS.CHECKED}`,

  VIEW_RECONFIRMED_LIST: `get_user_by_status_${STATUS.RECONFIRMED}`,
  EDIT_RECONFIRMED_LIST: `update_application_info_${STATUS.RECONFIRMED}`,
  UPDATE_STATUS_RECONFIRMED_TO_CHECKED: `change_application_status_from_${STATUS.RECONFIRMED}_to_${STATUS.CHECKED}`,
  UPDATE_STATUS_RECONFIRMED_TO_INPUT: `change_application_status_from_${STATUS.RECONFIRMED}_to_${STATUS.INPUT}`,

  VIEW_CHECKED_LIST: `get_user_by_status_${STATUS.CHECKED}`,
  EDIT_CHECKED_LIST: `update_application_info_${STATUS.CHECKED}`,
  UPDATE_STATUS_CHECKED_TO_PRINTED: `change_application_status_from_${STATUS.CHECKED}_to_${STATUS.PRINTED}`,
  UPDATE_STATUS_CHECKED_TO_RECONFIRMED: `change_application_status_from_${STATUS.CHECKED}_to_${STATUS.RECONFIRMED}`,
  UPDATE_STATUS_CHECKED_TO_RESERVE: `change_application_status_from_${STATUS.CHECKED}_to_${STATUS.RESERVE}`,

  VIEW_PRINTED_LIST: `get_user_by_status_${STATUS.PRINTED}`,
  UPDATE_STATUS_PRINTED_TO_INPUT: `change_application_status_from_${STATUS.PRINTED}_to_${STATUS.INPUT}`,
  EXPORT_EXCEL_PRINTED_LIST: `excel_export_application_${STATUS.PRINTED}`,
  UPDATE_STATUS_PRINTED_TO_CARD_UPDATE_LIST: `change_application_status_from_${STATUS.PRINTED}_to_${STATUS.CARD_UPDATE}`,

  VIEW_RESERVE_LIST: `get_user_by_status_${STATUS.RESERVE}`,
  EDIT_RESERVE_LIST: `update_application_info_${STATUS.RESERVE}`,
  UPDATE_STATUS_RESERVE_TO_INPUT: `change_application_status_from_${STATUS.RESERVE}_to_${STATUS.INPUT}`,
  UPDATE_STATUS_RESERVE_TO_CHECKED: `change_application_status_from_${STATUS.RESERVE}_to_${STATUS.CHECKED}`,

  VIEW_INPUT_LIST: `get_user_by_status_${STATUS.INPUT}`,
  EDIT_INPUT_LIST: `update_application_info_${STATUS.INPUT}`,
  UPDATE_STATUS_INPUT_TO_SENT: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.SENT}`,
  UPDATE_STATUS_FROM_INPUT_TO_PRINTED: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.PRINTED}`,
  UPDATE_STATUS_FROM_INPUT_TO_RESERVE: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.RESERVE}`,
  UPDATE_STATUS_FROM_INPUT_TO_CARD_UPDATE: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.CARD_UPDATE}`,
  UPDATE_STATUS_INPUT_TO_CONFIRMING: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.CONFIRMING}`,
  UPDATE_STATUS_INPUT_TO_CONFIRMED: `change_application_status_from_${STATUS.INPUT}_to_${STATUS.CONFIRMED}`,

  VIEW_SENT_LIST: `get_user_by_status_${STATUS.SENT}`,
  EDIT_SENT_LIST: `update_application_info_${STATUS.SENT}`,
  EXPORT_EXCEL_SENT_LIST: `excel_export_application_${STATUS.SENT}`,
  UPDATE_STATUS_SENT_TO_CONFIRMING: `change_application_status_from_${STATUS.SENT}_to_${STATUS.CONFIRMING}`,

  VIEW_CONFIRMING_LIST: `get_user_by_status_${STATUS.CONFIRMING}`,
  EDIT_CONFIRMING_LIST: `update_application_info_${STATUS.CONFIRMING}`,
  EXPORT_EXCEL_CONFIRMING_LIST: `excel_export_application_${STATUS.CONFIRMING}`,
  UPDATE_STATUS_CONFIRMING_TO_CONFIRMING: `change_application_status_from_${STATUS.CONFIRMING}_to_${STATUS.CONFIRMING}`,

  VIEW_CONFIRMED_LIST: `get_user_by_status_${STATUS.CONFIRMED}`,
  EDIT_CONFIRMED_LIST: `update_application_info_${STATUS.CONFIRMED}`,
  EXPORT_EXCEL_CONFIRMED_LIST: `excel_export_application_${STATUS.CONFIRMED}`,
  UPDATE_STATUS_CONFIRMED_TO_CONFIRMED_PREPARING_SHIPMENT: `change_application_status_from_${STATUS.CONFIRMED}_to_${STATUS.CONFIRMED_PREPARING_SHIPMENT}`,
  UPDATE_STATUS_CONFIRMED_TO_CONFIRMING: `change_application_status_from_${STATUS.CONFIRMED}_to_${STATUS.CONFIRMING}`,
  EXPORT_PDF_CONFIRMED_LIST: `export_pdf_${STATUS.CONFIRMED}`,

  VIEW_CONFIRMED_PREPARING_SHIPMENT_LIST: `get_user_by_status_${STATUS.CONFIRMED_PREPARING_SHIPMENT}`,
  EDIT_CONFIRMED_PREPARING_SHIPMENT_LIST: `update_application_info_${STATUS.CONFIRMED_PREPARING_SHIPMENT}`,
  EXPORT_EXCEL_CONFIRMED_PREPARING_SHIPMENT_LIST: `excel_export_application_${STATUS.CONFIRMED_PREPARING_SHIPMENT}`,
  UPDATE_STATUS_CONFIRMED_PREPARING_SHIPMENT_TO_COMPLETED: `change_application_status_from_${STATUS.CONFIRMED_PREPARING_SHIPMENT}_to_${STATUS.COMPLETED}`,
  EXPORT_PDF_CONFIRMED_PREPARING_SHIPMENT_LIST: `export_pdf_${STATUS.CONFIRMED_PREPARING_SHIPMENT}`,

  // Screen 8.2
  VIEW_CONFIRMED_RETURN: `get_user_by_status_${STATUS.CONFIRMED_RETURN}`,
  EDIT_CONFIRMED_RETURN_LIST: `update_application_info_${STATUS.CONFIRMED_RETURN}`,
  UPDATE_STATUS_CONFIRMED_RETURN_TO_CONFIRMED_PREPARING_RESHIPMENT: `change_application_status_from_${STATUS.CONFIRMED_RETURN}_to_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}`,
  EXPORT_PDF_CONFIRMED_RETURN_LIST: `export_pdf_${STATUS.CONFIRMED_RETURN}`,

  // Screen 8.3
  VIEW_CONFIRMED_PREPARING_RESHIPMENT: `get_user_by_status_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}`,
  EDIT_CONFIRMED_PREPARING_RESHIPMENT_LIST: `update_application_info_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}`,
  UPDATE_STATUS_CONFIRMED_PREPARING_RESHIPMENT_TO_COMPLETED: `change_application_status_from_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}_to_${STATUS.COMPLETED}`,
  EXPORT_PDF_CONFIRMED_PREPARING_RESHIPMENT_LIST: `export_pdf_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}`,
  EXPORT_CSV_CONFIRMED_PREPARING_RESHIPMENT_LIST: `excel_export_application_${STATUS.CONFIRMED_PREPARING_RESHIPMENT}`,

  VIEW_COMPLETED_LIST: `get_user_by_status_${STATUS.COMPLETED}`,
  EDIT_COMPLETED_LIST: `update_application_info_${STATUS.COMPLETED}`,
  EXPORT_EXCEL_COMPLETED_LIST: `excel_export_application_${STATUS.COMPLETED}`,
  EXPORT_PDF_COMPLETED_LIST: `export_pdf_${STATUS.COMPLETED}`,
  UPDATE_STATUS_COMPLETED_TO_CONFIRMED_RETURN: `change_application_status_from_${STATUS.COMPLETED}_to_${STATUS.CONFIRMED_RETURN}`,

  VIEW_REFUNDING_LIST: `get_user_by_status_${STATUS.REFUNDING}`,
  EDIT_REFUNDING_LIST: `update_application_info_${STATUS.REFUNDING}`,
  UPDATE_STATUS_REFUNDING_TO_REFUNDED: `change_application_status_from_${STATUS.REFUNDING}_to_${STATUS.REFUNDED}`,

  VIEW_REFUNDED_LIST: `get_user_by_status_${STATUS.REFUNDED}`,
  EDIT_REFUNDED_LIST: `update_application_info_${STATUS.REFUNDED}`,

  VIEW_CANCELED_LIST: `get_user_by_status_${STATUS.CANCELED}`,
  EDIT_CANCEL_LIST: `update_application_info_${STATUS.CANCELED}`,
  UPDATE_STATUS_CANCELED_TO_CHECKED: `change_application_status_from_${STATUS.CANCELED}_to_${STATUS.CHECKED}`,

  VIEW_CARD_VALID_LIST: `get_user_by_status_${STATUS.CARD_VALID}`,
  UPDATE_STATUS_COMPLETED_TO_CARD_VALID: `change_application_status_from_${STATUS.COMPLETED}_to_${STATUS.CARD_VALID}`,
  EDIT_CARD_VALID_LIST: `update_application_info_${STATUS.CARD_VALID}`,

  VIEW_CARD_UPDATE_LIST: `get_user_by_status_${STATUS.CARD_UPDATE}`,
  EDIT_CARD_UPDATE_LIST: `update_status_${STATUS.CARD_UPDATE}`,
  SEARCH_CARD_UPDATE_LIST: `search_user_by_status_${STATUS.CARD_UPDATE}`,
  EXPORT_EXCEL_CARD_UPDATE_LIST: `excel_export_application_${STATUS.CARD_UPDATE}`,
  UPDATE_STATUS_CARD_UPDATE_TO_INPUT_LIST: `change_application_status_from_${STATUS.CARD_UPDATE}_to_${STATUS.INPUT}`,

  VIEW_STATISTIC_NUMBER_APPLICATION: 'VIEW_STATISTIC_NUMBER_APPLICATION',
  EXPORT_EXCEL_STATISTIC_NUMBER_APPLICATION: 'EXPORT_EXCEL_STATISTIC_NUMBER_APPLICATION',

  ALLOW_EXPORT_EXCEL: "allow_export_excel",
  VIEW_ALL_REGISTRATION: "get_user",
  EDIT_ALL_REGISTRATION: "update_application_info",
  PASSPORT_IMAGE_EXPORT: "passport_image_export",
  API_SERVICE: "api_service",
  MAIL_SERVICE: "mail_service",
  STATISTIC_APPLICATION_BY_YEAR: "statistic_of_year",
  STATISTIC_APPLICATION_BY_DAYS: "statistic_days"
};

const formatNumber = num => {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
}

const formatAddress = item => {
  let address = '';
  if (item.buildingName) {
    address += item.buildingName + ', ';
  }
  if (item.afterAddress) {
    address += item.afterAddress + ', ';
  }
  if (item.city) {
    address += item.city + ', ';
  }
  if (item.prefecture) {
    address += item.prefecture + ', ';
  }
  if (item.country) {
    address += item.country;
  }
  if (address.indexOf(', ') === address.length - 2) {
    address = address.slice(0, address.indexOf(', '));
  }
  return address;
}

const formatInforUser = selectedUser => {
  const birthday = selectedUser.birthday;
  const issue = selectedUser.KYC.kycIssueDate;
  const expired = selectedUser.KYC.kycExpiredDate;
  let passportExpiry = {};
  if (expired === ' ') {
    passportExpiry = {
      passportExpiryYear: ' ',
      passportExpiryMonth: ' ',
      passportExpiryDate: ' ',
    }
  } else if (expired) {
    passportExpiry = {
      passportExpiryYear: expired.split('-')[0],
      passportExpiryMonth: expired.split('-')[1],
      passportExpiryDate: expired.split('-')[2],
    }
  }

  return {
    maleGender: selectedUser.gender ? 'male' : 'female',
    birthYear: birthday && birthday.split('-')[0],
    birthMonth: birthday && birthday.split('-')[1],
    birthDate: birthday && birthday.split('-')[2],
    passportIssueYear: issue && issue.split('-')[0],
    passportIssueMonth: issue && issue.split('-')[1],
    passportIssueDate: issue && issue.split('-')[2],
    passportImage: selectedUser.KYC.KYC_1,
    passportPlaceIssued: selectedUser.KYC.kycPlaceIssued,
    passportNumber: selectedUser.KYC.KYC_NUMBER,
    KYC_1: selectedUser.KYC.KYC_1,
    KYC_2: selectedUser.KYC.KYC_2,
    KYC_3: selectedUser.KYC.KYC_3,
    KYC_4: selectedUser.KYC.KYC_4,
    ...passportExpiry
  };
}

function getBase64File(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function convertBase64toFile({urlData, mimeType, filename = 'newFile'}) {
  mimeType = mimeType || (urlData.match(/^data:([^;]+);/)||'')[1];
  const res = await fetch(urlData);
  const blob = await res.blob();
  return new File([blob], filename, {type: mimeType});
}

const applicationInfoOnLocal = (businessMemberId) => {
  const initialValues = {};
  const allRegistration = JSON.parse(localStorage.getItem('REGISTRATION_TRIPLE'));
  if (!allRegistration || !allRegistration[businessMemberId] || !allRegistration[businessMemberId].applicationInfo) {
    return null;
  }
  const applicationInfo = allRegistration[businessMemberId].applicationInfo;

  Object.keys(applicationInfo).forEach(key => {
    if(key === 'gender') {
      const gender = applicationInfo['gender'] ? 'male' : 'female';
      initialValues.gender = gender;
      return;
    }
    if(key === 'birthday') {
      initialValues.birthday = moment(applicationInfo.birthday, 'YYYY-M-D');
      return;
    }
    if(key === 'issueDate')  {
      initialValues.issueDate = moment(applicationInfo.issueDate, 'YYYY-M-D');
      return;
    }
    if(key === 'expiredDate' && applicationInfo.expiredDate != '')  {
      initialValues.expiredDate = moment(applicationInfo.expiredDate, 'YYYY-M-D');
      return;
    }

    initialValues[key] = applicationInfo[key];
  })

  return initialValues;
}

const arrayImage = (value) => {
  const { linkPassportImage, linkSelfieImage, linkBankStatmentImage, linkBackImage, KYC_1, KYC_2, KYC_3, KYC_4 } = value;

  const arrImage = [
    {
      label01: 'KYC image font upload',
      label02: 'Identity Card Image Font',
      image: linkPassportImage,
      blur: 'BLUR FONT',
      name: 'KYC_1',
      value: KYC_1,
      rotate: 0,
      zoom: 150,
      updateImage: null,
    },
    {
      label01: 'KYC image selfie upload',
      label02: 'Identity Card Image Selfie',
      image: linkSelfieImage,
      blur: 'BLUR SELFIE',
      name: 'KYC_3',
      value: KYC_3,
      rotate: 0,
      zoom: 150,
      updateImage: null,
    },
    {
      label01: 'KYC image bank statement upload',
      label02: 'Identity Card Image Bank Statement',
      image: linkBankStatmentImage,
      blur: 'WRONG ADDRESS DOC',
      name: 'KYC_4',
      value: KYC_4,
      rotate: 0,
      zoom: 150,
      updateImage: null,
    }
  ];

  const imageBack = {
    label01: 'KYC image back upload',
    label02: 'Identity Card Image back',
    image: linkBackImage,
    blur: '',
    name: 'KYC_2',
    value: KYC_2,
    rotate: 0,
    zoom: 150,
    updateImage: null,
  };

  if (linkBackImage)
    arrImage.splice(1, 0, imageBack);

  return arrImage;
}

const objectImage = (name, linkImage) => {
  const data = {};
  switch (name) {
    case "KYC_1": {
      data.linkPassportImage = linkImage;
      break;
    }
    case "KYC_2": {
      data.linkBackImage = linkImage;
      break;
    }
    case "KYC_3": {
      data.linkSelfieImage = linkImage;
      break;
    }
    case "KYC_4": {
      data.linkBankStatmentImage = linkImage;
      break;
    }
  }

  return data;
}

const setValueImage = (index, value, data) => {
  const {businessId, nationality, KYC_1,KYC_1_OLD, KYC_2, KYC_2_OLD, KYC_3, KYC_3_OLD, KYC_4, KYC_4_OLD} = data;
  if (businessId === businessIds.TRIPLE.id && ['China', 'HongKong'].includes(nationality)) {
    switch (index) {
      case 0:
        value = value ? KYC_1 : value;
        value = KYC_1_OLD ? KYC_1_OLD : value;
        break;
      case 1:
        value = value ? KYC_2 : value;
        value = KYC_2_OLD ? KYC_2_OLD : value;
        break;
      case 2:
        value = value ? KYC_3 : value;
        value = KYC_3_OLD ? KYC_3_OLD : value;
        break;
      case 3:
        value = value ? KYC_4 : value;
        value = KYC_4_OLD ? KYC_4_OLD : value;
        break;
      default: break;
    }
  } else {
    switch (index) {
      case 0:
        value = value ? KYC_1 : value;
        value = KYC_1_OLD ? KYC_1_OLD : value;
        break;
      case 1:
        value = value ? KYC_3 : value;
        value = KYC_3_OLD ? KYC_3_OLD : value;
        break;
      case 2:
        value = value ? KYC_4 : value;
        value = KYC_4_OLD ? KYC_4_OLD : value;
        break;
      default: break;
    }
  }

  return value;
}

export {
  formatDateTimeFull,
  formatCardNumber,
  getLatestSuccessStep,
  parseUrlQuery,
  getCurrentStepFromUrl,
  getFromUpdatedInfo,
  getInfoHistoryUpdated,
  formatNumber,
  allCountries,
  STATUS,
  STATUS_NUMBER,
  Validation,
  TYPE_SEND_MAIL,
  allProfession,
  STATUS_USER,
  PERMISSION,
  ROLE_SUPPER_ADMIN,
  STATUS_CARD_NUMBER,
  formatAddress,
  formatInforUser,
  professionOptions,
  getBase64File,
  applicationInfoOnLocal,
  convertBase64toFile,
  arrayImage,
  objectImage,
  setValueImage
};
